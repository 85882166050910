import { Suspense, forwardRef, lazy, useEffect, useImperativeHandle, useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import { BaseReactPlayerProps } from 'react-player/base'

const Loader = () => {
    return (
        <div className='w-10 h-10 border-[2px] bg-accordorange rounded-full animate-spin'></div>
    )
}

const VideoPlayer = forwardRef((props: BaseReactPlayerProps, ref) => {
    const [Component, setComponent] = useState<React.ComponentType<BaseReactPlayerProps> | null>(null);
    const playerRef = useRef<ReactPlayer>(null);

    useEffect(() => {
        setComponent(() => lazy(() => import('react-player/lazy')));
    }, []);

    useImperativeHandle(ref, () => ({
        seekTo: (seconds: number) => {
            if (playerRef.current) {
                playerRef.current.seekTo(seconds);
            }
        }
    }));

    if (!Component) {
        return <Loader />;
    }

    return (
        <Suspense fallback={<Loader />}>
            <Component ref={playerRef} {...props} />
        </Suspense>
    );
});


export default VideoPlayer;